import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/Users/matthewbrindley/dev/tilt-frontend/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/Users/matthewbrindley/dev/tilt-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/Users/matthewbrindley/dev/tilt-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/Users/matthewbrindley/dev/tilt-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/matthewbrindley/dev/tilt-frontend/src/components/marketing/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar","NavbarItem"] */ "/Users/matthewbrindley/dev/tilt-frontend/src/components/ui/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarLayout"] */ "/Users/matthewbrindley/dev/tilt-frontend/src/components/ui/sidebar-layout.tsx");
